
<script setup lang="ts">
    import { isMobile } from "o365.GlobalState.ts";

    export interface IProps {
        text?: string;
    };

    const props = defineProps<IProps>();
    const model = defineModel<boolean>();

    const id = crypto.randomUUID();
</script>

<template>
    <OSwitch size="sm" v-model="model" :label="text" labelSide="left" />
    <!-- <div class="flex-shrink-0 form-check form-switch form-check-reverse" :class="{ 'form-switch-md': isMobile }"> -->
        <!-- <label :for="id" class="form-check-label fw-medium text-muted">{{ text }}</label> -->
        <!-- <input :id="id" class="form-check-input" type="checkbox" role="switch" v-model="model"> -->
    <!-- </div> -->
</template>

<style scoped>
/* updated */
.form-switch:not(.form-switch-md) > .form-check-input {
    margin-top: 0.375rem;
    margin-left: 0rem;
}
.form-switch-md > .form-check-input {
    margin-top: 0.125rem;
    margin-left: 0.375rem;
}


/* https://stackoverflow.com/a/60969539 */

.form-check-input {
  clear: left;
}

.form-switch.form-switch-sm {
  /*margin-bottom: 0.5rem;*/ /* JUST FOR STYLING PURPOSE */
}

.form-switch.form-switch-sm .form-check-input {
  height: 1rem;
  width: calc(1rem + 0.75rem);
  border-radius: 2rem;
}

.form-switch.form-switch-md {
  /*margin-bottom: 1rem;*/ /* JUST FOR STYLING PURPOSE */
}

.form-switch.form-switch-md .form-check-input {
  height: 1.5rem;
  width: calc(2rem + 0.75rem);
  border-radius: 3rem;
}

.form-switch.form-switch-lg {
  /*margin-bottom: 1.5rem;*/ /* JUST FOR STYLING PURPOSE */
}

.form-switch.form-switch-lg .form-check-input {
  height: 2rem;
  width: calc(3rem + 0.75rem);
  border-radius: 4rem;
}

.form-switch.form-switch-xl {
  margin-bottom: 2rem; /* JUST FOR STYLING PURPOSE */
}

.form-switch.form-switch-xl .form-check-input {
  height: 2.5rem;
  width: calc(4rem + 0.75rem);
  border-radius: 5rem;
}
</style>
